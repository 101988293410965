/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { graphql } from "gatsby";

import {
  BackLink,
  Card,
  Heading,
  PortableText,
  Layout,
  ProtectedImage,
} from "components";
import { getArtistName, getArtworkPath } from "utils";
import { Fragment } from "react";

function ViewingRoomPage({
  data: {
    viewingRoom: {
      title,
      end_date,
      start_date,
      content,
      artworks,
      display_thumbnails,
      display_large_first_image,
    },
  },
}) {
  const [firstArtwork, ...restArtworks] = artworks;

  return (
    <Layout pageTitle={[title, "Viewing Rooms"]} artworks={artworks}>
      {({ openArtworkModal }) => {
        const handleArtworkClick = (artwork) => (evt) => {
          evt.preventDefault();
          openArtworkModal({ artworkId: artwork._id });
        };
        return (
          <Fragment>
            <BackLink to="/viewing-rooms" sx={{ mb: "s" }}>
              viewing rooms
            </BackLink>
            <Heading mb="s" sx={{ fontSize: "24px" }}>
              {title}
            </Heading>

            <Text as="p">
              {[start_date, end_date].filter(Boolean).join(" - ")}
            </Text>

            <div
              sx={{
                mb: "l",
                display: "flex",
                flexDirection: ["column", "row-reverse"],
                width: "100%",
              }}
            >
              {display_large_first_image && firstArtwork && (
                <div
                  sx={{
                    ml: [null, "l"],
                    mb: ["l"],
                    width: [null, "400px", "600px"],
                    flexGrow: 0,
                    flexShrink: 0,
                  }}
                >
                  <div
                    onClick={handleArtworkClick(firstArtwork)}
                    sx={{ mb: "xxs", cursor: "pointer" }}
                  >
                    <ProtectedImage
                      loading="eager"
                      image={
                        firstArtwork.work_image__large.asset.gatsbyImageData
                      }
                      alt={title}
                    />
                  </div>
                  <Text as="p">
                    {[
                      getArtistName(firstArtwork.artist),
                      firstArtwork?.work_title || firstArtwork?.name,
                      firstArtwork?.work_date,
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  </Text>
                </div>
              )}

              <div sx={{ flexGrow: 1, flexShrink: 1 }}>
                {content && <PortableText content={content} />}
              </div>
            </div>

            {display_thumbnails && (
              <div
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  mx: "-s",
                  clear: "both",
                }}
              >
                {(display_large_first_image ? restArtworks : artworks).map(
                  (artwork) => (
                    <div
                      key={artwork._id}
                      sx={{ m: "m", mt: "s", width: "220px" }}
                    >
                      <Card
                        to={getArtworkPath(artwork)}
                        gatsbyImageData={
                          artwork?.work_image?.asset?.gatsbyImageData
                        }
                        imageAlt={artwork.name}
                        onClick={handleArtworkClick(artwork)}
                        title={getArtistName(artwork.artist)}
                        subTitle={[artwork.name, artwork.work_date]
                          .filter(Boolean)
                          .join(", ")}
                      />
                    </div>
                  )
                )}
              </div>
            )}
          </Fragment>
        );
      }}
    </Layout>
  );
}

export const query = graphql`
  query ViewingRoom($id: String) {
    viewingRoom: sanityViewingRoom(_id: { eq: $id }) {
      _id
      title
      end_date(formatString: "Do MMMM YYYY")
      start_date(formatString: "Do MMMM YYYY")
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      artworks {
        _id
        work_image {
          asset {
            gatsbyImageData(height: 220)
          }
        }
        work_image__large: work_image {
          asset {
            gatsbyImageData(height: 440)
          }
        }
        image: work_image {
          asset {
            gatsbyImageData(height: 800)
          }
        }
        image__short: work_image {
          asset {
            gatsbyImageData(height: 500)
          }
        }
        name
        medium {
          medium
        }
        work_date
        work_title
        content: _rawDetails(resolveReferences: { maxDepth: 10 })
        artist {
          _id
          first_name
          last_name
          yearOfDeath: date_of_death(formatString: "yyyy")
          yearOfBirth: date_of_birth(formatString: "yyyy")
        }
      }
      display_thumbnails
      display_large_first_image
    }
  }
`;

export default ViewingRoomPage;
